import { TeamsDataContext } from '@components/context/TeamsContext';
import { getUserThemes } from '@services/themes';
import { useQuery } from '@tanstack/react-query';
import { useContext, useRef } from 'react';
import { type Theme } from '../store/ThemeStore';

type UserDetails = {
  default_theme_id: string,
  themes: Theme[]
}

export const GET_THEMES_KEY = 'get_themes';

export default function useFetchThemes({ enabled = true }: { enabled?: boolean } = {}) {
  const { currentTeam } = useContext(TeamsDataContext);

  const darkThemeId = useRef(undefined);

  const userDetailsId = currentTeam?.billing_account?.owner?.user_details_id;

  const { data, error, isLoading, isSuccess, refetch } = useQuery({
    queryKey: [GET_THEMES_KEY, userDetailsId],
    queryFn: () => getUserThemes({ userDetailsId }),
    enabled: !!userDetailsId && enabled
  });

  let userThemes: UserDetails | undefined;
  let defaultThemes: UserDetails | undefined;

  if (data?.entity) {
    for (const userDetails of data.entity) {
      if (userDetails.id === userDetailsId) userThemes = userDetails;
      else {
        darkThemeId.current = userDetails.themes.find((theme: Theme) => theme.name === 'Dark' && !theme.config.default.imageSrc)?.id;
        defaultThemes = userDetails;
      }
    }
  }

  const defaultThemeId = userThemes?.default_theme_id;

  const getThemeSettings = (selectedThemeId?: string | null) => {
    if (!userThemes || !defaultThemes) {
      return {
        colors: undefined,
        settings: undefined
      };
    }
    const themeId = selectedThemeId || defaultThemeId || darkThemeId;
    const theme = [...userThemes.themes, ...defaultThemes.themes].find(theme => theme.id === themeId);
    if (!theme) {
      return {
        colors: undefined,
        settings: undefined
      };
    }
    return {
      colors: theme.config.colors,
      settings: { ...theme.config.textFontFamily, ...theme.config.headingFontFamily, ...theme.config.images }
    };
  };

  return {
    userThemes: userThemes?.themes,
    defaultThemes: defaultThemes?.themes,
    defaultThemeId,
    darkThemeId: darkThemeId.current,
    error,
    isLoading,
    isSuccess,
    refetch,
    getThemeSettings
  };
}
