import { type Theme } from '@components/themes/store/ThemeStore';
import { deleteWithAuth, getWithAuth, getWithAuthServerSide, postWithAuth, putWithAuth } from '@services/http.service';
import { UrlParamsReplace } from '@services/url.service';

function GET_USER_THEMES(userDetailsId: string) {
  return UrlParamsReplace('themes/:userDetailsId', { userDetailsId });
}

function CREATE_THEME(userDetailsId: string) {
  return UrlParamsReplace('themes/:userDetailsId', { userDetailsId });
}

function UPDATE_THEME(themeId: string) {
  return UrlParamsReplace('themes/:themeId', { themeId });
}

function DELETE_THEME(themeId: string) {
  return UrlParamsReplace('themes/:themeId', { themeId });
}

function SET_DEFAULT_THEME() {
  return UrlParamsReplace('themes/default-theme');
}

function SET_SELECTED_THEME() {
  return UrlParamsReplace('themes/selected-theme');
}

type GetUserThemes = {
  userDetailsId: string;
}

type CreateTheme = {
  theme: Theme;
  userDetailsId: string;
  createdBy: string;
}

type UpdateTheme = {
  theme: Theme;
  updatedBy: string;
}

type SetDefaultTheme = {
  themeId: string;
  userDetailsId: string;
}

type SetSelectedTheme = {
  themeId: string;
  templateId: string;
}

/**
 *
 * @param userDetailsId pass in the user details id and not user id
 * @returns
 */
export const getUserThemes = ({ userDetailsId }: GetUserThemes) => getWithAuth(GET_USER_THEMES(userDetailsId));

export const getUserThemesServerSide = ({ userDetailsId, auth }: any) => getWithAuthServerSide(GET_USER_THEMES(userDetailsId), auth);

export const createUserTheme = ({ theme, userDetailsId, createdBy }: CreateTheme) => postWithAuth(CREATE_THEME(userDetailsId), { theme, createdBy });

export const updateUserTheme = ({ theme, updatedBy }: UpdateTheme) => putWithAuth(UPDATE_THEME(theme.id), { theme, updatedBy });

export const deleteUserTheme = (themeId: string) => deleteWithAuth(DELETE_THEME(themeId));

export const setDefaultTheme = ({ themeId, userDetailsId }: SetDefaultTheme) => postWithAuth(SET_DEFAULT_THEME(), { themeId, userDetailsId });

export const setSelectedTheme = ({ themeId, templateId }: SetSelectedTheme) => postWithAuth(SET_SELECTED_THEME(), { themeId, templateId });
