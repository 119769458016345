import { TeamsDataContext } from '@components/context/TeamsContext';
import { getUserDetails } from '@services/youtube-platform.service';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

export default function useFetchUserDetails() {
  const { teamContext } = useContext(TeamsDataContext);
  return useQuery({
    queryKey: ['userDetails'],
    queryFn: async () => {
      const userDetails = await getUserDetails(teamContext?.oid);
      return userDetails;
    },
    enabled: !!teamContext?.oid,
  });
}
