import type { Interaction, InteractionType, Platforms } from './types.ts';

export const panelBtnEnum = {
  content: 'Content',
  design: 'Design',
  themes: 'Themes'
} as const;

export const PLATFORMS: Record<string, Platforms> = {
  ipa: 'In-person',
  hybrid: 'Hybrid',
  zoom: 'Zoom',
  meet: 'Google Meet',
  teams: 'Microsoft Teams',
  youtube: 'YouTube',
  twitch: 'Twitch',
  vimeo: 'Vimeo',
  webex: 'Webex',
  other: 'Other online platforms',
  linkedin: 'LinkedIn',
  restream: 'Restream',
  streamyard: 'Streamyard',
  airmeet: 'Airmeet',
  kick: 'Kick'
};

export const AUTHORIZATION_PLATFORMS = [
  PLATFORMS.youtube,
  PLATFORMS.twitch,
  PLATFORMS.teams
];

export const interactionEnum: {
  [key in InteractionType]: InteractionType;
} = {
  pollWithOptions: 'pollWithOptions',
  freeForm: 'freeForm', // poll without options
  map: 'map',
  wordCloud: 'wordCloud',
  newWheel: 'newWheel', // winning wheel
  emojisEverywhere: 'emojisEverywhere',
  talkingTiles: 'talkingTiles',
  transientThought: 'transientThought',
  wheel: 'wheel', // choice circle
  'Quick Questions': 'Quick Questions',
  linkLibrary: 'linkLibrary',
  textTrack: 'textTrack',
  'PDF Slide': 'PDF Slide',
  ratingPolls: 'ratingPolls',
  fantasticFans: 'fantasticFans',
  multipleChoice: 'multipleChoice'
};

const defaultColor: Interaction['color'] = {
  pollHeadingColor: '#FFFFFF',
  pollWithOptionsHeadingColor: '#FFFFFF',
  wcHeadingColor: '#FFFFFF',
  mapHeadingColor: '#FFFFFF',
  ratingPollsHeadingColor: '#FFFFFF',
  winningWheelHeadingColor: '#FFFFFF',
  choiceCircleHeadingColor: '#FFFFFF',
  quickQuestionHeadingColor: '#FFFFFF',
  linkLibraryHeadingColor: '#FFFFFF',
  talkingTilesHeadingColor: '#FFFFFF',
  textTrackHeadingColor: '#FFFFFF',
  emojisEverywhereHeadingColor: '#FFFFFF',
  ttHeadingColor: '#FFFFFF',
  ffHeadingColor: '#FFFFFF',
  mapBgColor: '#000000',
  mapLandColor: '#FFFFFF',
  mapWaterColor: '#B7CAE6',
  bigCircleColor: '#FF0064',
  midCircleColor: '#FF0064',
  smallCircleColor: '#FF0064',
  latestPointColor: '#9567F9',
  individualPointColor: '#FF0064',
  emojisEverywhereBgColor: '#111111',
  pollBgColor: '#111111',
  pollChartColor: '#F97B5F',
  pollLabelColor: '#FFFFFF',
  pollWithOptionsBgColor: '#111111',
  pollWithOptionsChartColor: '#F97B5F',
  pollWithOptionsLabelColor: '#FFFFFF',
  ratingPollsBgColor: '#111111',
  ratingPollsLabelColor: '#FFFFFF',
  ratingPollsChartColor: '#F97B5F',
  talkingTileBgColor1: '#6101DC',
  talkingTileBgColor2: '#F501DD',
  talkingTileBgColor3: '#0501DC',
  talkingTilesBgColor: '#111111',
  talkingTilesTextColor: '#111111',
  textTrackBgColor: '#111111',
  textTrackMultipleColors: true,
  textTrackWordColor: '#319795',
  chatBubble1: '#8942DF',
  chatBubble2: '#56CB77',
  ttTextColor1: '#111111',
  ttTextColor2: '#111111',
  ttBgColor: '#111111',
  ttmultipleColors: false,
  winningWheelBgColor: '#111111',
  choiceCircleBgColor: '#111111',
  linkLibraryBgColor: '#111111',
  quickQuestionBgColor: '#111111',
  wcBgColor: '#111111',
  wcWordColor: '#319795',
  multipleColors: false,
  ffBgColor: '#111111',
  ffTextColor: '#FFFFFF',
  coBgColor: '#F5F5F5',
  coCommentColor: '#000000',
  coUsernameColor: '#000000',
  linkLibraryTextColor: '#FFFFFF',
  quickQuestionTextColor: '#FFFFFF',
  multipleChoiceBgColor: '#111111',
  multipleChoiceChartColor: '#F97B5F',
  multipleChoiceLabelColor: '#FFFFFF',
  multipleChoiceHeadingColor: '#FFFFFF'
};

export const defaultInteractionConfig: Interaction = {
  title: '',
  type: 'pollWithOptions',
  color: { ...defaultColor },
  options: [],
  setting: {
    DefaultView: 'world',
    transientThoughtShowUsername: true,
    LinkType: 'all',
    transientThoughtDisplayStyle: 'cloud',
    CombineWords: false,
    CommentsOverlay: true,
    DisplayStyle: 'percentage',
    FontStyle: 'Sans-Serif',
    oneUoneV: false,
    OptionsList: 'number',
    ProfanityFilter: true,
    showLatestCommentsFirst: false,
    showUserName: true,
    SplitWords: false,
    ttPlaybackSpeed: 'default',
    Vertical: true,
    wheelShowUser: '1',
    prefix: '',
    points: '',
    showResponses: true,
  }
};

export const PRESENT_TIMER_SECS = 10;
